@import "core";
.page-checkout-ticketselection {
	.ticketselection-heromessage {
		@include font-medium;
		border-radius: $radius-general;
		background-color: $color-bg;
		padding: 2rem 2rem;
		
		&:not(:last-child) {
			margin-bottom: 2.2rem;
		}
	}

	.ticketselection-tickets {
		width: 100%;
	}

		.tickets-ticket {
			display: flex;
			align-items: flex-end;

			@include media {
				display: block;
			}

			+ .tickets-ticket {
				margin-top: 2rem;

				@include media {
					padding-top: 2rem;
					border-top: .1rem solid $color-border;
				}
			}
		}

			.ticket-option {
				position: relative;
				flex: 1 0 0;
			}

			.ticket-slot {
				position: relative;
				width: 10rem;
				margin-left: 1.2rem;

				@include media {
					width: auto;
					margin: 1.2rem 0 0;
				}
			}

			.ticket-removebtn {
				margin-left: 1.2rem;

				@include media {
					margin: 1.2rem 0 0;
				}
			}

	.ticketselection-addbtn {
		margin-top: 3.2rem;
	}

	.ticketselection-notes {
		color: $color-text-light;
		margin-top: 2rem;
	}

	.section.footer {
		padding-bottom: calc(9rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}
}